<template>
  <div v-if="objects && objects.length" class="bookmarks">
    <div v-for="(object, index) in objects" :key="`o_${object.id}`" class="my-3 card-bg">
      <ion-badge color="medium clickable" class="del" @click="deleteBookmark(object.id)"
        ><i class="ti-minus"
      /></ion-badge>
      <character-card-small
        v-if="object.type === 'character'"
        :key="`c_${object.id}`"
        :character="object"
        :show-comment-counts="false"
        @afterReaction="(reactionResp: any) => onReaction(index, reactionResp)"
      />
      <world-card-small
        v-else-if="object.type === 'world'"
        :key="`w_${object.id}`"
        :world="object"
        :member-count="get(memberCounts, `${object.id}.member_count`) || 0"
        :world-unread-count="get(worldUnreadCounts, `${object.id}.worldunread_msgs_count`) || 0"
        @afterReaction="(reactionResp: any) => onReaction(index, reactionResp)"
      />
      <blab-card-small
        v-else-if="object.type === 'blab'"
        :key="object.id"
        :replies-count="get(blabRepliesCount, `${object.id}.blab_replies_count`) || 0"
        :post="object"
        class="clickable"
        @open="openBlabModal"
      />
      <div v-else-if="object.type === 'image'" class="image-card d-flex-vertical">
        <img
          v-image
          :src="resizeUpload(object.image, '190h')"
          class="image-preview clickable-item-hov"
          @click.prevent="openVisualPreview(object)"
        />
        <div class="rest-info image-info">
          <router-link :to="{ name: 'profile', params: { username: object.username } }" class="clickable">{{
            object.username
          }}</router-link>
        </div>
      </div>
    </div>
    <p v-show="nextPageExists" class="clickable" @click="requestLoadMore">Load More</p>
    <p v-show="!nextPageExists" class="list-footer">End of list</p>
  </div>
  <div v-else class="no-data"><span class="list-footer">No bookmarks here yet!</span></div>

  <VisualPreviewModal
    :image-url="selectedVisualImg"
    :image-id="selectedVisualImageId"
    :is-open="visualPreviewModalIsOpen"
    modal-title="Bookmarked Image"
    :caption="selectedVisCaption"
    :source="selectedVisSource"
    @dismiss-modal="closeVisualPreviewModal"
  />
  <blab-details-modal
    :is-open="openBlabAsModal"
    :blabid="blabId"
    @close-modal="openBlabAsModal = false"
    @edit="openEditBlab"
    @dismiss-modal="openBlabAsModal = false"
  />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Image } from '@/shared/types/static-types';
import VisualPreviewModal from '@/shared/modals/VisualPreviewModal.vue';
import BlabDetailsModal from '@/shared/modals/BlabDetailsModal.vue';
import CharacterCardSmall from '@/shared/components/CharacterCardSmall.vue';
import WorldCardSmall from '@/shared/components/WorldCardSmall.vue';
import BlabCardSmall from '@/shared/components/BlabCardsmall.vue';
import { getWorldsMemberCounts, getWorldsUnreadCounts } from '@/shared/actions/worlds';
import { getCountofBlabReplies } from '@/shared/actions/blabs';
import { resizeUpload } from '@/shared/utils/upload';

@Options({
  name: 'BookmarkCollectionInfiniteObjects',
  components: {
    VisualPreviewModal,
    WorldCardSmall,
    CharacterCardSmall,
    BlabCardSmall,
    BlabDetailsModal,
  },
})
export default class BookmarkCollectionInfiniteObjects extends Vue {
  @Prop() public currentTab!: string;
  @Prop() public objects!: any[];
  @Prop() public paging!: any;
  resizeUpload = resizeUpload;
  public areMemberCountsLoading = false;
  public areWorldUnreadCountsLoading = false;
  public memberCounts = {};
  public worldUnreadCounts = {};

  public selectedVisualImg = '';
  public selectedVisualImageId = '';
  public selectedVisCaption = '';
  public selectedVisSource = '';
  public visualPreviewModalIsOpen = false;

  public blabId = '';
  public openBlabAsModal = false;
  public blabRepliesCount = {};

  get = get;

  public get nextPageExists() {
    return !!this.paging.next;
  }

  @Emit('onLoadMore')
  public requestLoadMore() {}

  @Emit('onDelete')
  public deleteBookmark(id: string) {
    return id;
  }

  public get bookmarkedWorlds() {
    return this.objects.filter((o: any) => o.type === 'world');
  }

  public get bookmarkedBlabs() {
    return this.objects.filter((o: any) => o.type === 'blab');
  }

  public onReaction(index: number, reaction: any) {
    this.objects[index] = { ...this.objects[index], ...reaction.updatedReactionsData };
  }

  public openVisualPreview(img: Image) {
    this.selectedVisualImg = img.image || '/empty.png';
    this.selectedVisualImageId = img.id || '';
    this.visualPreviewModalIsOpen = true;
    this.selectedVisCaption = img.description || '';
    this.selectedVisSource = img.source || '';
  }

  public closeVisualPreviewModal() {
    this.visualPreviewModalIsOpen = false;
    this.selectedVisualImg = '';
    this.selectedVisualImageId = '';
    this.selectedVisCaption = '';
    this.selectedVisSource = '';
  }

  public async fetchWorldsMemberCounts() {
    this.areMemberCountsLoading = true;
    this.memberCounts = {};
    const resp = await getWorldsMemberCounts(map(this.bookmarkedWorlds, 'id'));
    this.memberCounts = keyBy(resp, 'world_id');
    this.areMemberCountsLoading = false;
  }

  public async fetchWorldUnreadCounts() {
    this.areWorldUnreadCountsLoading = true;
    this.worldUnreadCounts = {};
    const resp = await getWorldsUnreadCounts(map(this.bookmarkedWorlds, 'id'));
    this.worldUnreadCounts = keyBy(resp, 'world_id');
    this.areWorldUnreadCountsLoading = false;
  }

  public async fetchBlabReplies() {
    this.blabRepliesCount = {};
    const resp = await getCountofBlabReplies(map(this.bookmarkedBlabs, 'id'));
    this.blabRepliesCount = keyBy(resp, 'blab');
  }

  public openBlabModal(id: string) {
    this.blabId = id;
    this.openBlabAsModal = true;
  }

  public openEditBlab(_: boolean, id: string) {
    const router = useRouter();
    this.openBlabAsModal = false;
    router.push({ name: 'edit-post', params: { id } });
  }

  @Watch('bookmarkedWorlds')
  worldListingChanged() {
    if (get(this.bookmarkedWorlds, 'length')) {
      this.fetchWorldsMemberCounts();
      this.fetchWorldUnreadCounts();
    }
  }

  @Watch('bookmarkedBlabs')
  blabsChanged() {
    this.fetchBlabReplies();
  }
}
</script>

<style scoped lang="sass">
.card-bg
  border-radius: 8px
  padding: 0.5rem
  background: #FFF
  position: relative
  display: flex
  justify-content: center
  ::v-deep

    .blab-card, .character-card-small
      width: 100%
      max-width: 400px
.list-footer
  text-align: center
.image-preview
  width: 100px
  height: 100px
  object-fit: cover
  border-radius: 8px
.image-card
  padding: 0.5rem
  margin: 0 auto
  width: 200px
  display: block
  text-align: center
.clickable-item-hov
  background: none
.del
  position: absolute
  z-index: 1
  right: 0.5rem
  width: 30px
</style>
