<template>
  <ion-page class="page scrollable">
    <bookmark-collection-header :collection="collectionDetails" />
    <bookmark-collection-infinite-objects
      :objects="objects"
      :paging="paging"
      @onLoadMore="loadMore"
      @onDelete="deleteBookmark"
    />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BookmarkCollectionHeader from './components/BookmarkCollectionHeader.vue';
import BookmarkCollectionInfiniteObjects from './components/BookmarkCollectionInfiniteObjects.vue';
import { toast } from '@/shared/native';
import { BookmarkCollection } from '@/shared/types/static-types';
import { getBookmarkCollectionObjects, getBookmarkCollectionFromSlug, unbookmark } from '@/shared/actions/bookmarks';

@Options({
  name: 'BookmarkCollectionHomePage',
  components: {
    BookmarkCollectionHeader,
    BookmarkCollectionInfiniteObjects,
  },
})
export default class BookmarkCollectionHomePage extends Vue {
  collectionDetails: BookmarkCollection = {
    id: '',
  };

  public objects = [];
  public paging: any = {};
  public count = '';
  public currPage = 1;
  get = get;

  public get collectionSlug() {
    const router = useRouter();
    return router.currentRoute.value.params.slug;
  }

  public async fetchBookmarkCollection() {
    const router = useRouter();
    try {
      const document = useDocument();
      const response = await getBookmarkCollectionFromSlug(router.currentRoute.value.params.slug);
      this.collectionDetails = response;
      this.fetchBookmarkedObjects();

      document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    } catch (e) {
      await toast.show(`Couldn't load bookmarks`, 'nonative', 'danger');
      router.go(-1);
    }
  }

  public async fetchBookmarkedObjects() {
    const router = useRouter();
    try {
      const { results, ...paging } = await getBookmarkCollectionObjects(this.collectionDetails.id);
      this.objects = results;
      this.count = paging.count;
      this.paging = paging;
      this.currPage = 1;
    } catch (e) {
      await toast.show(`Couldn't load bookmarks`, 'nonative', 'danger');
      router.go(-1);
    }
  }

  public async loadMore() {
    try {
      this.currPage += 1;
      const { results, ...paging } = await getBookmarkCollectionObjects(this.collectionDetails.id, this.currPage);
      this.objects = this.objects.concat(results);
      this.paging = paging;
    } catch (e) {
      await toast.show(`Couldn't load more bookmarks. Please try again.`, 'nonative', 'danger');
    }
  }

  public async deleteBookmark(id: string) {
    try {
      await unbookmark({ collection: this.collectionDetails.id, id });
      this.objects = this.objects.filter((obj: any) => obj.id !== id);
    } catch (e) {
      await toast.show(`Error encountered while unbookmarking. Please try again.`, 'nonative', 'danger');
    }
  }

  mounted() {
    this.fetchBookmarkCollection();
  }
}
</script>

<style></style>
