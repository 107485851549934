<template>
  <div class="w-100 position-relative d-flex flex-column align-items-center wrapper">
    <div class="cover" :style="{ 'background-image': `url(${resizeUpload(cover, '800w')})` }" />

    <img loading="lazy" v-image class="profile position-absolute" :src="resizeUpload(profilePicture, '250x250')" />

    <div class="name d-flex mt-4 mb-3">
      {{ name }}

      <ion-button
        v-if="get(user, 'id') === get(collection, 'user.id')"
        color="transparent"
        class="ml-2 mt-1 inline-button edit"
        @click="edit"
      >
        Edit
      </ion-button>
    </div>

    <div v-if="description" class="description d-flex">
      <div v-html="description" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { resizeUpload } from '@/shared/utils/upload';
import { BookmarkCollection } from '@/shared/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';

@Options({
  name: 'BookmarkCollectionHeader',
})
export default class BookmarkCollectionHeader extends Vue {
  @Prop() collection!: BookmarkCollection;

  resizeUpload = resizeUpload

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get = get;

  public get cover() {
    if (!this.collection) return '/empty.png';
    return this.collection.banner_img_url;
  }

  public get profilePicture() {
    if (!this.collection || !this.collection.cropped_img_url) return '/empty.png';
    return this.collection.cropped_img_url;
  }

  public get name() {
    return this.collection.name;
  }

  public get description() {
    return this.collection.description;
  }

  public edit() {
    const router = useRouter();
    router.push({
      name: 'edit-bookmark-collection',
      params: { id: this.collection.id },
    });
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  padding-bottom: 100px
  .cover
    height: 240px
    width: 100%
    background-position: center
    background-size: cover
    background-repeat: no-repeat
  .profile
    width: 200px
    height: 200px
    top: 150px
    object-fit: cover
    border-radius: 10px
  .name
    position: relative
    font-size: 24px
    font-weight: bold
    top: 100px
    .edit
      font-size: 14px
      color: #7f10b3
      font-weight: bold
  .description
    position: relative
    font-size: 16px
    background: rgba(121, 121, 121, 0.2)
    padding: 0 1rem
    border-radius: 10px
    top: 100px
</style>
